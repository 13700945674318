<template>
  <apexchart
    :options="chartOptions"
    :series="series"
    :height="height"
  />
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import moment from '@/libs/moment'
import DataDomainApexService from '@/service/datadomain-apex.service'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    timeRange: {
      type: Object,
      default: () => ({
        startDate: moment().subtract(30, 'days').toDate(),
        endDate: new Date(),
      }),
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
    height: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      chartDataLoaded: false,
      isRendered: false,
      statistic: [],
    }
  },
  computed: {
    asupId() {
      return this.$route.params.id
    },
    startDate() {
      if (this.$route.query.start) {
        return this.$route.query.start
      }

      return this.timeRange.startDate
    },
    endDate() {
      if (this.$route.query.end) {
        return this.$route.query.end
      }

      return this.timeRange.endDate
    },
    chartTitle() {
      return `Used Capacity ${this.$moment(this.startDate).format('L')} - ${this.$moment(this.endDate).format('L')}`
    },
    chartOptions() {
      return {
        chart: {
          id: 'postCompTreeMap',
          type: 'treemap',
          height: this.height,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          show: true,
          curve: 'straight',
          lineCap: 'butt',
          width: 1.5,
        },
        markers: {
          size: 0,
        },
        title: {
          text: this.chartTitle,
          align: 'left',
        },
        yaxis: [
          {
            seriesName: 'Post dedup',
            labels: {
              formatter(val) {
                if (val) {
                  return `${(val).toFixed(0)} GiB`
                }
                return val
              },
            },
            title: {
              text: 'Capacity Post dedup',
            },
            minWidth: 0,
          },
        ],
        xaxis: {
          categories: this.xAxisCategories,
        },
        tooltip: {
          shared: true,
          inverseOrder: true,
          onDatasetHover: {
            highlightDataSeries: true,
          },
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (val) {
                return `${(+(val).toFixed(0)).toLocaleString('en-US')} GiB`
              }

              return null
            },
          },
        },
      }
    },
    series() {
      return [{
        data: this.statistic.reduce((p, c) => {
          if (c.avgPostComp === 0) { // dont add 0 data (to prevent miss-rendering of chart)
            return p
          }
          let mtree = p.find(d => d.x === c.mtree)
          if (!mtree) {
            mtree = { x: c.mtree, y: 0 }
            p.push(mtree)
          }

          // Use highest avg post comp as value
          const avgPostCompGib = c.avgPostComp / 1024 / 1024 / 1024
          if (avgPostCompGib > mtree.y) {
            mtree.y = avgPostCompGib
          }

          return p
        }, []),
      }]
    },
  },
  watch: {
    timeRange() {
      this.loadStatistic()
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    loadStatistic() {
      DataDomainApexService.getStatisticAggListByMtreeAsync(this.asupId, {
        from: moment(this.startDate).subtract(1, 'days').startOf('day').toISOString(),
        to: moment(this.endDate).add(1, 'days').endOf('day').toISOString(),
      }, {
        disableTenantFilter: true,
      })
        .then(result => {
          this.statistic = result.items
          this.chartDataLoaded = true
        })
    },
  },
}

</script>
