<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr v-if="asset && asset.account">
                <th>Account</th>
                <td colspan="2">
                  {{ asset.account.name }}
                </td>
              </tr>
              <tr>
                <th>Hostname</th>
                <td colspan="2">
                  <b-link
                    v-if="asup.parentSystemId"
                    :to="{name: 'datadomain-detail.general', params: {id: asup.parentSystemId}}"
                  >
                    {{ asup.hostname }}
                  </b-link>
                  <template v-else>
                    {{ asup.hostname }}
                  </template>
                </td>
              </tr>
              <tr>
                <th>Serialnumber</th>
                <td colspan="2">
                  {{ asup.serial }}
                </td>
              </tr>
              <tr>
                <th>Model</th>
                <td colspan="2">
                  {{ asup.model }}
                </td>
              </tr>
              <tr>
                <th>Version</th>
                <td colspan="2">
                  {{ asup.version }}
                </td>
              </tr>
              <tr>
                <th>System Timezone</th>
                <td colspan="2">
                  {{ asup.timezone }}
                </td>
              </tr>
              <tr>
                <th>Last autosupport data</th>
                <td colspan="2">
                  {{ asup.updateTimestampUtc | formatDateTime }}
                </td>
              </tr>
              <tr v-if="asup.updateTimestampUtc > asup.lastBuildModelTimestamp">
                <th>Model built on (UTC)</th>
                <td colspan="2">
                  {{ asup.lastBuildModelTimestamp | formatDateTime }}<br>
                  <small class="text-hint text-danger">The displayed data is older than the latest Autosupport information. The displayed data should be updated a few minutes after the latest Autosupport data.</small>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr>
                </td>
              </tr>
              <tr v-if="asup.details.calculated.usageExceedsMaxCapacityBase365On">
                <th>Usage exceeds max. capacity <small class="text-muted">(Base {{ forecastBaseDays }} days)</small></th>
                <td>{{ asup.details.calculated.usageExceedsMaxCapacityBase365On | formatDate }}</td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <template #header>
            <h4 class="card-title">
              Monitor Status <small class="text-muted">({{ monitors.length }} monitors checked)</small>
            </h4>
            <div
              v-if="isMonitorOverwritten"
              class="float-right"
            >
              <b-badge
                v-b-popover.hover="'At least one monitor has been overwritten for this system.'"
                pill
                variant="info"
              >
                <small>Monitor overwritten <feather-icon icon="HelpCircleIcon" /></small>
              </b-badge>
            </div>
          </template>

          <ul
            v-if="activeMonitors.length > 0"
            style="list-style: none"
          >
            <li
              v-for="monitor in activeMonitors"
              :id="`monitor_${monitor.monitorId}`"
              :key="monitor.monitorId"
            >
              <monitor-status-icon
                :status="monitor.status"
                :text="monitor.shortMessage || monitor.exceptionMessage"
              />

              <b-popover
                custom-class="wide-popover"
                :target="`monitor_${monitor.monitorId}`"
                triggers="hover"
                :variant="popoverVariant(monitor.status)"
              >
                <template #title>
                  Detailed message
                </template>

                <span
                  v-if="monitor.message"
                  v-html="monitor.message"
                />
                <span v-else>No detailed message</span>
              </b-popover>
            </li>
          </ul>
          <div v-else>
            <monitor-status-icon
              :status="0"
              text="All monitors are OK"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <ApexTrendChart
        :asup="asup"
      />
    </b-card>

    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['thisMonth', 'lastMonth', 'last2Months', 'last3Months', 'last6Months', 'thisYear', 'last2Years', 'last3Years', 'last5Years']"
            :max-selectable-days="2000"
          />
        </b-form-group>
      </b-form>
      <ApexConfigChart
        :asup="asup"
        :time-range="timeRange"
      />
    </b-card>
    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRangeTreeMap"
            :selectable-ranges="['thisMonth', 'lastMonth', 'last2Months', 'last3Months', 'last6Months', 'thisYear', 'last2Years', 'last3Years', 'last5Years']"
            :max-selectable-days="2000"
          />
        </b-form-group>
      </b-form>
      <PostCompTreeMapChart
        :asup="asup"
        :time-range="timeRangeTreeMap"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, VBPopover, BPopover, BBadge, BLink, BForm, BFormGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import MonitorStatusIcon from '@/components/monitor/MonitorStatusIcon.vue'
import ApexConfigChart from './charts/ApexConfigChart.vue'
import ApexTrendChart from './charts/ApexTrendChart.vue'
import AsupStatusService from '@/service/asupStatus.service'
import PostCompTreeMapChart from './charts/PostCompTreeMapChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    MonitorStatusIcon,
    BPopover,
    BBadge,
    BLink,
    BForm,
    BFormGroup,
    ApexConfigChart,
    ApexTrendChart,
    DateRangePicker,
    PostCompTreeMapChart,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      monitors: [],
      isLoading: false,
      timeRange: {
        range: 'last365Days',
      },
      timeRangeTreeMap: {
        range: 'thisMonth',
      },
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    activeMonitors() {
      return this.monitors.filter(x => x.status !== 0)
    },
    isMonitorOverwritten() {
      if (this.isHost && this.asup.isHostMonitorOverwritten) {
        return true
      }
      if (!this.isHost && this.asup.isTenantMonitorOverwritten) {
        return true
      }

      return false
    },
    forecastBaseDays() {
      let baseDays = 365
      if (this.asup.calculateForecastFrom) {
        const calculateForecastFrom = this.$moment.utc(this.asup.calculateForecastFrom)
        const diffDays = Math.round(this.$moment.duration(this.$moment.utc().diff(calculateForecastFrom)).asDays())
        if (diffDays < 365) {
          baseDays = diffDays
        }
      }

      return baseDays
    },
  },
  created() {
    AsupStatusService.getActiveListAsync({ asupSystemId: this.asup.id })
      .then(result => {
        this.monitors = result.items
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    popoverVariant(status) {
      switch (status) {
        case 0: return 'success'
        case 10: return 'info'
        case 20: return 'warning'
        case 30:
          return 'danger'
        default: return 'danger'
      }
    },
  },
}
</script>
