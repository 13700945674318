<template>
  <apexchart
    type="line"
    :options="chartOptions"
    :series="[comittedCapacitySeries, maxCapacitySeries, postCompSeries, postCompTrendingSeries, maxCapacityPredictionSeries, comittedCapacityPredictionSeries]"
    :height="350"
  />
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import moment from '@/libs/moment'
import DataDomainApexService from '@/service/datadomain-apex.service'
import calculation from '@/utils/calculation'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
  },
  data() {
    return {
      chartDataLoaded: false,
      isRendered: false,
      statistic: [],
    }
  },
  computed: {
    asupId() {
      return this.$route.params.id
    },
    chartTitle() {
      return 'Data usage forecast'
    },
    chartOptions() {
      return {
        chart: {
          id: 'trendChart',
          type: 'line',
          height: 350,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        colors: ['#008FFB', '#00E396', '#FF4560', '#7F00FF', '#00E396', '#008FFB'],
        stroke: {
          show: true,
          curve: ['stepline', 'stepline', 'straight', 'straight', 'straight', 'straight'],
          lineCap: 'butt',
          width: [3.5, 3.5, 3.5, 2, 3.5, 3.5],
          dashArray: [0, 0, 0, 2, 8, 8],
        },
        annotations: this.trendAnnotations,
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: this.chartTitle,
          align: 'left',
        },
        yaxis: [
          {
            labels: {
              formatter(val) {
                if (val) {
                  return `${(val).toFixed(0)} GiB`
                }
                return val
              },
            },
            minWidth: 0,
            min: 0,
          },
        ],
        xaxis: {
          type: 'datetime',
          min: moment.utc().subtract(365, 'days').startOf('day').valueOf(),
          max: moment.utc().add(730, 'days').startOf('day').valueOf(),
        },
        tooltip: {
          shared: true,
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (val) {
                return `${(+(val).toFixed(0)).toLocaleString('en-US')} GiB`
              }

              return null
            },
          },
        },
      }
    },
    trendAnnotations() {
      const annotations = {
        points: [],
      }

      if (this.asup
        && this.asup.details.calculated.usageExceedsMaxCapacityBase365On
        && moment.utc(this.asup.details.calculated.usageExceedsMaxCapacityBase365On).isAfter(moment.utc(), 'day')) {
        annotations.points.push({
          x: moment(this.asup.details.calculated.usageExceedsMaxCapacityBase365On).valueOf(),
          y: this.maxCapacitySeries.data[this.maxCapacitySeries.data.length - 1][1],
          marker: {
            size: 8,
            fillColor: '#fff',
            strokeColor: '#00E396',
            radius: 2,
          },
          label: {
            borderColor: '#00E396',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#00E396',
            },

            text: `Exceeds max. capacity on ${this.$options.filters.formatDate(this.asup.details.calculated.usageExceedsMaxCapacityBase365On)}`,
          },
        })
      }

      if (this.asup
        && this.asup.details.calculated.usageExceedsComittedCapacityBase365On
        && moment.utc(this.asup.details.calculated.usageExceedsComittedCapacityBase365On).isAfter(moment.utc(), 'day')) {
        annotations.points.push({
          x: moment(this.asup.details.calculated.usageExceedsComittedCapacityBase365On).valueOf(),
          y: this.comittedCapacitySeries.data[this.maxCapacitySeries.data.length - 1][1], // TODO
          marker: {
            size: 8,
            fillColor: '#fff',
            strokeColor: '008FFB',
            radius: 2,
          },
          label: {
            borderColor: '#008FFB',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#008FFB',
            },

            text: `Exceeds comitted capacity on ${this.$options.filters.formatDate(this.asup.details.calculated.usageExceedsComittedCapacityBase365On)}`,
          },
        })
      }

      return annotations
    },
    comittedCapacitySeries() {
      const series = {
        name: 'Comitted Capacity',
        data: [],
      }

      if (this.asup && this.asup.details && this.asup.details.config && this.asup.details.config.comittedCapacity) {
        series.data = this.asup.details.config.comittedCapacity.map(x => [this.$moment.utc(x.date).format('YYYY-MM-DD'), x.value / 1024 / 1024 / 1024])

        const lastData = this.asup.details.config.comittedCapacity[this.asup.details.config.comittedCapacity.length - 1]
        if (lastData && this.$moment.utc(lastData.date) < this.$moment.utc()) {
          series.data.push([this.$moment.utc().toISOString(), lastData.value / 1024 / 1024 / 1024])
        }
      }

      return series
    },
    comittedCapacityPredictionSeries() {
      const series = {
        name: 'Committed Capacity prediction',
        data: [],
      }

      if (this.comittedCapacitySeries.data.length > 0) {
        const lastCommittedCapacityData = this.comittedCapacitySeries.data[this.comittedCapacitySeries.data.length - 1]

        series.data.push(lastCommittedCapacityData)
        series.data.push([moment.utc().add(365, 'days').startOf('day').format('YYYY-MM-DD'), lastCommittedCapacityData[1]])
      }

      return series
    },
    maxCapacitySeries() {
      const series = {
        name: 'Max. Capacity',
        data: [],
      }

      if (this.asup && this.asup.details && this.asup.details.config && this.asup.details.config.maxCapacity) {
        series.data = this.asup.details.config.maxCapacity.map(x => [this.$moment.utc(x.date).format('YYYY-MM-DD'), x.value / 1024 / 1024 / 1024])

        const lastData = this.asup.details.config.maxCapacity[this.asup.details.config.maxCapacity.length - 1]
        if (lastData && this.$moment.utc(lastData.date) < this.$moment.utc()) {
          series.data.push([this.$moment.utc().toISOString(), lastData.value / 1024 / 1024 / 1024])
        }
      }

      return series
    },
    maxCapacityPredictionSeries() {
      const series = {
        name: 'Max. Capacity prediction',
        data: [],
      }

      if (this.maxCapacitySeries.data.length > 0) {
        const lastMaxCapacityData = this.maxCapacitySeries.data[this.maxCapacitySeries.data.length - 1]

        series.data.push(lastMaxCapacityData)
        series.data.push([moment.utc().add(365, 'days').startOf('day').format('YYYY-MM-DD'), lastMaxCapacityData[1]])
      }

      return series
    },
    postCompSeries() {
      const series = {
        name: 'Used Capacity',
        data: this.statistic.map(x => [this.$moment.utc(x.meassureDate).format('YYYY-MM-DD'), x.postComp / 1024 / 1024 / 1024]),
      }

      return series
    },
    postCompTrendingSeries() {
      const series = {
        name: 'Used Capacity Trend',
        data: [],
      }

      let forecastBaseData = this.postCompSeries.data
      let statsFrom = moment.utc().subtract(365, 'days').startOf('day')
      if (this.asup.calculateForecastFrom && moment.utc(this.asup.calculateForecastFrom) > statsFrom) {
        statsFrom = moment.utc(this.asup.calculateForecastFrom)

        forecastBaseData = forecastBaseData.filter(x => this.$moment.utc(x[0]) >= statsFrom)
      }

      const postCompSeries = this.calculateForecastChartSeriesData(forecastBaseData, 365)

      series.data = postCompSeries

      return series
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    loadStatistic() {
      DataDomainApexService.getStatisticDailyAggList(this.asupId, {
        from: moment.utc().subtract(365, 'days').startOf('day').toISOString(),
        to: moment.utc().add(-1, 'days').endOf('day').toISOString(), // load statistic until end of last day, because current day is not finished yet and my contain invalid data
      }, {
        disableTenantFilter: true,
      })
        .then(result => {
          this.statistic = result.items
          this.chartDataLoaded = true
        })
    },
    calculateForecastChartSeriesData(data, forecastDays) {
      if (!data || data.length === 0) {
        return []
      }

      // make sure we have one datapoint for each day (use max. usedInPercent if multiple datapoints exists for one day)
      // eslint-disable-next-line no-unused-vars
      const groupByDay = data.reduce((p, c) => {
        const [timestamp, value] = c

        // remove invalid values
        if (!value) {
          return p
        }

        const timestampKey = moment(timestamp).format('YYYY-MM-DD')
        // eslint-disable-next-line no-param-reassign
        p[timestampKey] = p[timestampKey] || []
        p[timestampKey].push(c)
        return p
      }, {})

      const filteredData = []
      // eslint-disable-next-line guard-for-in, no-restricted-syntax, no-unused-vars
      for (const day in groupByDay) {
        const maxValue = Math.max(...groupByDay[day].map(x => x[1]))

        filteredData.push({
          timestamp: groupByDay[day].find(x => x[1] === maxValue)[0],
          value: maxValue,
        })
      }

      const xdata = filteredData.map((x, index) => index)
      const ydata = filteredData.map(x => x.value)

      // eslint-disable-next-line no-unused-vars
      const resultRegress = calculation.regress(xdata, ydata)

      // eslint-disable-next-line no-unused-vars
      const last = filteredData[filteredData.length - 1]

      const forecastDate = this.adjustByTimezone(this.$moment.utc(last.timestamp)).add(forecastDays, 'days').format('YYYY-MM-DD')
      const forecastValue = resultRegress.slope * (xdata[xdata.length - 1] + forecastDays) + resultRegress.intercept

      return [
        [this.adjustByTimezone(this.$moment.utc(filteredData[0].timestamp)).format('YYYY-MM-DD'), resultRegress.intercept],
        [forecastDate, forecastValue],
      ]
    },
    adjustByTimezone(dateTime) {
      const offset = this.$moment.tz.zone(this.asup.timezone).utcOffset(dateTime)
      return dateTime.clone().subtract(offset, 'minutes')
    },
  },
}

</script>
