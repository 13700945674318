<template>
  <apexchart
    type="line"
    :options="chartOptions"
    :series="[comittedCapacitySeries, maxCapacitySeries,targetCapacity, postCompAllMtreeSeries]"
  />
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import moment from '@/libs/moment'
import DataDomainApexService from '@/service/datadomain-apex.service'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    timeRange: {
      type: Object,
      default: () => ({
        startDate: moment().subtract(14, 'days').toDate(),
        endDate: new Date(),
      }),
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
  },
  data() {
    return {
      chartDataLoaded: false,
      isRendered: false,
      statistic: [],
    }
  },
  computed: {
    asupId() {
      return this.$route.params.id
    },
    startDate() {
      if (this.$route.query.start) {
        return this.$route.query.start
      }

      return this.timeRange.startDate
    },
    endDate() {
      if (this.$route.query.end) {
        return this.$route.query.end
      }

      return this.timeRange.endDate
    },
    chartTitle() {
      return `Used Capacity with configuration ${this.$moment(this.startDate).format('L')} - ${this.$moment(this.endDate).format('L')}`
    },
    chartOptions() {
      return {
        chart: {
          id: 'configChart',
          type: 'line',
          height: 550,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          show: true,
          curve: ['stepline', 'stepline', 'smooth', 'straight', 'straight'],
          lineCap: 'butt',
          width: [3.5, 3.5, 3.5, 3.5, 2],
          colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560'],
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1, 2, 3],
          background: {
            dropShadow: {
              enabled: true,
            },
          },
          formatter(val) {
            if (val) {
              return `${(+(val).toFixed(0)).toLocaleString('en-US')} GiB`
            }
            return val
          },
        },
        markers: {
          size: 0,
        },
        title: {
          text: this.chartTitle,
          align: 'left',
        },
        yaxis: [
          {
            labels: {
              formatter(val) {
                if (val) {
                  return `${(val).toFixed(0)} GiB`
                }
                return val
              },
            },
            minWidth: 0,
            min: 0,
          },
        ],
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (val) {
                return `${(+(val).toFixed(0)).toLocaleString('en-US')} GiB`
              }

              return null
            },
          },
        },
      }
    },
    comittedCapacitySeries() {
      const series = {
        name: 'Comitted Capacity',
        data: [],
      }

      if (this.asup && this.asup.details && this.asup.details.config && this.asup.details.config.comittedCapacity) {
        series.data = this.asup.details.config.comittedCapacity.map(x => [this.$moment.utc(x.date).format('YYYY-MM-DD'), x.value / 1024 / 1024 / 1024])

        const lastData = this.asup.details.config.comittedCapacity[this.asup.details.config.comittedCapacity.length - 1]
        if (lastData && this.$moment.utc(lastData.date) < this.$moment.utc()) {
          series.data.push([this.$moment.utc().toISOString(), lastData.value / 1024 / 1024 / 1024])
        }
      }

      return series
    },
    maxCapacitySeries() {
      const series = {
        name: 'Max. Capacity',
        data: [],
      }

      if (this.asup && this.asup.details && this.asup.details.config && this.asup.details.config.maxCapacity) {
        series.data = this.asup.details.config.maxCapacity.map(x => [this.$moment.utc(x.date).format('YYYY-MM-DD'), x.value / 1024 / 1024 / 1024])

        const lastData = this.asup.details.config.maxCapacity[this.asup.details.config.maxCapacity.length - 1]
        if (lastData && this.$moment.utc(lastData.date) < this.$moment.utc()) {
          series.data.push([this.$moment.utc().toISOString(), lastData.value / 1024 / 1024 / 1024])
        }
      }

      return series
    },
    targetCapacity() {
      const series = {
        name: 'Target Capacity',
        data: [],
      }

      if (this.asup && this.asup.details && this.asup.details.config && this.asup.details.config.targetCapacity) {
        series.data = this.asup.details.config.targetCapacity.map(x => [this.$moment.utc(x.date).format('YYYY-MM-DD'), x.value / 1024 / 1024 / 1024])

        const lastData = this.asup.details.config.targetCapacity[this.asup.details.config.targetCapacity.length - 1]
        if (lastData && this.$moment.utc(lastData.date) < this.$moment.utc()) {
          series.data.push([this.$moment.utc().toISOString(), lastData.value / 1024 / 1024 / 1024])
        }
      }

      /*
      // fill gaps
      const that = this
      const normalizedData = []
      const startDate = that.$moment.utc(this.startDate)
      const endDate = that.$moment.utc(this.endDate)
      const days = that.$moment.duration(endDate.diff(startDate)).asDays()
      for (let i = 0; i <= days + 2; i += 1) {
        const date = startDate.clone().add(i, 'days')

        const d = series.data.find(x => that.$moment(x[0]).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'))
        if (d) {
          normalizedData.push(d)
        } else {
          const filtered = series.data.filter(x => that.$moment(x[0]) >= date)
          const min = filtered.map(x => that.$moment(x[0]))
          const nextExistingDate = that.$moment.min(min).format('YYYY-MM-DD')
          normalizedData.push([
            date.format('YYYY-MM-DD'),
            filtered.length === 0 ? 0 : series.data.find(x => x[0] === nextExistingDate)[1],
          ])
        }
      }

      series.data = normalizedData
*/

      return series
    },
    postCompAllMtreeSeries() {
      const series = {
        name: 'Used Capacity',
        data: [],
      }

      if (this.statistic) {
        const groupedData = this.statistic.reduce((p, c) => {
          const key = `${c.month}.${c.year}`
          // eslint-disable-next-line no-param-reassign
          p[key] = p[key] || {
            year: c.year,
            month: c.month,
            avgPostComp: 0,
          }

          // eslint-disable-next-line no-param-reassign
          p[key].avgPostComp += c.avgPostComp
          return p
        }, {})

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in groupedData) {
          const data = groupedData[key]
          series.data.push([this.$moment.utc({ year: data.year, month: data.month - 1, day: 1 }).format('YYYY-MM-DD'), data.avgPostComp / 1024 / 1024 / 1024])
        }
      }

      return series
    },
  },
  watch: {
    timeRange() {
      this.loadStatistic()
    },
    mtreeName() {
      this.loadStatistic()
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    loadStatistic() {
      DataDomainApexService.getStatisticAggListByMtreeAsync(this.asupId, {
        from: moment(this.startDate).subtract(1, 'days').startOf('day').toISOString(),
        to: moment(this.endDate).add(1, 'days').endOf('day').toISOString(),
      }, {
        disableTenantFilter: true,
      })
        .then(result => {
          this.statistic = result.items
          this.chartDataLoaded = true
          this.updateChart()
        })
    },
    updateChart() {
      const opts = this.chartOptions
      const xAxisMax = moment(this.endDate).endOf('day')

      // Update X axis with new time range
      opts.xaxis = this.$apexcharts.merge(opts.xaxis, {
        min: moment(this.startDate).startOf('day').valueOf(),
        max: xAxisMax.valueOf(),
      })

      this.$apexcharts.exec(this.chartOptions.chart.id, 'updateOptions', opts)
    },
  },
}

</script>
